import React from 'react'
import { graphql } from 'gatsby';
import Image from 'gatsby-image'

import Layout from 'components/Layout'
import SEO from 'components/SEO';
import Content from 'components/Content';
import Hero from 'components/Hero';
import Projects from 'components/Projects';

import "styles/CaseTemplate.scss";
import { getLanguage } from '../services/language'
import ParseContent from '../components/ParseContent';

const Project = ({ data, location }) => {
  const { posts } = data
  const url = location.href ? location.href : '';

  const { title, acf: { hero, project } } = data.post;
  const language = data.post.wpml_current_locale.slice(0, 2);

  return (
    <Layout>
      <SEO yoast={{ meta: data.post.yoast_meta }} image={data.post.acf.banner.localFile.publicURL} lang={language} pathname={url} />

      <div className="klantcase-page">
        <Hero
          className="klantcase-hero"
          fluidImage={hero.image.localFile.childImageSharp.fluid}
          title={title}
          description={hero.description}
        />

        <section className="case">
          <div className="container">
            <div className="row introduction mb-5">
              <div className="col-lg-6">

                <Content className="introduction-content " content={project.introduction} />

                {hero.imageSecond && (
                  <img src={hero.imageSecond.localFile.publicURL} width="100%" className="mb-5 mb-lg-0" />
                )}

              </div>
              <div className="col-lg-6 pl-0 pr-0">
                <Image
                  fixed={project.image.localFile.childImageSharp.fixed}
                  className="introduction-image"
                />
              </div>
            </div>
            <div className="row case-content">
              <div className="col-md-12 col-lg-10">
                <ParseContent className="case-content" content={project.post} />
              </div>
            </div>
          </div>
        </section>

        {posts.length > 0 && <Projects otherProjects={posts} />}
      </div>
    </Layout>
  )
}

export default Project

export const query = graphql`
  query ProjectByID($wordpress_id: Int!, $language: String!) {
    posts: allWordpressPost(filter: { 
      status: { eq: "publish" }
      wordpress_id: { ne: $wordpress_id }
      wpml_current_locale: { eq: $language }
    }) {
      edges {
        node {
          title
          path
          acf {
            banner {
              localFile {
                childImageSharp {
                  fixed(quality: 100, width: 340, height: 318) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
      
    post: wordpressPost(
      wordpress_id: { eq: $wordpress_id }
    ) {
      id
      wordpress_id
      title
      slug
      content
      wpml_current_locale
      yoast_meta {
        name
        content
        property
      }
      acf {
        hero {
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 99, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageSecond: image_second {
            localFile {
              publicURL
            }
          }
        }

        project: content {
          post
          image {
            localFile {
              childImageSharp {
                fixed(quality: 100, width: 720, height: 487) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
          introduction
        }

        banner {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`
